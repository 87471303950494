import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "react-and-typescript"
    }}>{`React and TypeScript`}</h1>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`I created `}<a parentName="p" {...{
        "href": "http://reactandtypescript.dev"
      }}>{`React And TypeScript`}</a>{` as a way for me to learn React and TypeScript. I decided to share my notes online which is exactly what you're looking at here. I hope for this project to grow and be `}<em parentName="p">{`THE`}</em>{` default source for React and TypeScript documentation.`}</p>
    <h2 {...{
      "id": "run-locally"
    }}>{`Run Locally`}</h2>
    <p>{`In your terminal at the project root, run:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`yarn`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`yarn start`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "contributing"
    }}>{`Contributing`}</h2>
    <p>{`Thank you for your interest in contributing to the `}<a parentName="p" {...{
        "href": "https://www.reactandtypescript.dev/"
      }}>{`React And TypeScript`}</a>{` unoffical documentation. Please visit the `}<a parentName="p" {...{
        "href": "./CONTRIBUTING.md"
      }}>{`Contributing page`}</a>{` to learn more about contributing to this project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      